import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaDiscord, FaGithub, FaReact, FaNodeJs, FaHtml5, FaPython } from "react-icons/fa";

const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Web Developer.", "Discord Bot Coder.", "Vps Seller.", "Minecraft Servers Seller"],
      loop: true,
      typeSpeed: 50,
      deleteSpeed: 20,
      delaySpeed: 1500,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">YOU ARE LANDING INTO MY LIFE</h4>
        <h1 className="text-6xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">Crazy Tech</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#0188ff"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          <b>Hello 👋. I am Mohit Gandhi, I am 16 years old web developer. I like spending time on discord and minecraft. I provide services like free discord bot hosting, cheap minecraft hosting as well as cheap vps services. Techstar has been up for an year now 🖥 and we have made lots of progress and have more than 500s of feedbacks and a good trust rating. 💯</b>
        </p>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          <b>As much as i like gaming 🎮. I am very pastionate about my skills and abilities 💻. I have been professionally managing many discord servers with thousands of members over these years and yet never been so flexy about it. I have always tried to help people and will continue doing so. This is the main reason for me to provide free hosting to everyone. I would never include paid plans if i had money to afford giving everything for free. ❤</b>
        </p>
        </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <p className="text-base uppercase font-titleFont mb-4">
            FIND ME AT
          </p>
          <div className="flex gap-4">
            <span className="bannerIcon">
            <a href="https://discord.gg/devshub">
      <button>
       <FaDiscord size='28px'/>
      </button>
            </a>
            </span>
            <span className="bannerIcon">
            <a href="https://github.com/crazytech69">
      <button>
       <FaGithub size='27px'/>
      </button>
            </a>
            </span>
          </div>   
          </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
              <FaReact />
            </span>
            <span className="bannerIcon">
              <FaNodeJs />
            </span>
            <span className="bannerIcon">
              <FaHtml5 /> 
            </span>
            <span className="bannerIcon">
              <FaPython />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftBanner