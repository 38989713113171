import React from 'react'
import Title from '../layouts/Title'
import { yt1, yt2, yt3, yt4, yt5, yt6 } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="My Coding Tutorials"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Host Bot On VPS"
          des=" This video is for users who want to host their bot on VPS."
          src={yt1}
        />
        <ProjectsCard
          title="Free Bot Hosting"
          des=" This video is for users who want free hosting "
          src={yt2}
        />
        <ProjectsCard
          title="Usage of WinSCP"
          des=" This video is for users who want to learn how to use WinSCP"
          src={yt3}
        />
        <ProjectsCard
          title="Advanced Music Bot"
          des=" This video is for users who want to learn how to make an advanced music bot."
          src={yt4}
        />
        <ProjectsCard
          title="Discord Bot Dashboard"
          des=" This video is for users who want to learn how to make a dashboard for their bot.P"
          src={yt5}
        />
        <ProjectsCard
          title="Discord Permissions Calculator"
          des=" This video is for users who want to calculate permissions for their bot."
          src={yt6}
        />
      </div>
    </section>
  );
}

export default Projects